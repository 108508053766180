import type { AriaToastRegionProps } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import { useToastRegion } from "@react-aria/toast";
import React from "react";
import { Toast } from "./Toast";
import { AnimatePresence, motion } from "framer-motion";
import { useToaster } from "./ToastProvider";
import { useMediaQuery } from "~/hooks/use-media-query";

interface ToastRegionProps<T> extends AriaToastRegionProps {
  state: ToastState<T>;
}

export function ToastRegion<T extends React.ReactNode>({ state, ...props }: ToastRegionProps<T>) {
  let ref = React.useRef(null);
  const { close } = useToaster();
  let { regionProps } = useToastRegion<T>(props, state, ref);
  const isMd = useMediaQuery("(min-width: 768px)");

  const onDragEnd = (offset: number, key: string) => {
    if ((isMd && offset < 100) || (!isMd && offset < 100)) {
      close(key);
    }
  };

  return (
    <div
      {...regionProps}
      ref={ref}
      className="fixed z-[100000] bottom-0 left-0 right-0 md:left-auto md:right-0 p-2 outline-none flex flex-col gap-2"
    >
      <AnimatePresence mode="popLayout">
        {state.visibleToasts.map((toast, index, array) => (
          <motion.div
            key={toast.key}
            layout
            className="w-full"
            transition={{ duration: 0.2, ease: "easeOut" }}
            initial={isMd ? { opacity: 0, y: 20 } : { opacity: 0, y: 20 }}
            animate={isMd ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
            drag={isMd ? "y" : "y"}
            dragSnapToOrigin
            dragConstraints={
              isMd ? { top: 0, bottom: 100, left: 0, right: 0 } : { top: 0, bottom: 100, left: 0, right: 0 }
            }
            onDragEnd={(event, info) => onDragEnd(isMd ? info.offset.x : info.offset.y, toast.key)}
          >
            <Toast toast={toast} state={state} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}
