import { useToastState } from "@react-stately/toast";
import { ToastRegion } from "./ToastRegion";
import { createContext, useCallback, useContext } from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

type ToastProps = {
  message: React.ReactNode | string;
  icon?: React.ReactNode;
  autoClose?: boolean | number;
  onClose?(props: ToastProps): void;
  onOpen?(props: ToastProps): void;
};

type ToastWithFixedIconProps = Omit<ToastProps, "icon">;

export const ToastsContext = createContext<{
  toast: (notification: ToastProps) => void;
  error: (notification: ToastWithFixedIconProps) => void;
  success: (notification: ToastWithFixedIconProps) => void;
  close: (key: string) => void;
}>({
  toast: (notification: ToastProps) => {},
  error: (notification: ToastWithFixedIconProps) => {},
  success: (notification: ToastWithFixedIconProps) => {},
  close: (key: string) => {},
});

export function useToaster() {
  return useContext(ToastsContext);
}

export function ToastProvider({ children, ...props }: { children: React.ReactNode }) {
  const state = useToastState<React.ReactNode>({
    maxVisibleToasts: 5,
  });

  const toast = useCallback(
    (props: ToastProps) => {
      state.add(
        <div className="flex items-center justify-start gap-4 text-left">
          {props.icon}
          {props.message}
        </div>,
        {
          timeout: 5000,
        }
      );
    },
    [state]
  );

  const close = useCallback(
    (key: string) => {
      state.close(key);
    },
    [state]
  );

  const error = useCallback(
    (props: ToastWithFixedIconProps) => {
      toast({
        ...props,
        icon: (
          <div className="size-5 text-white p-0.5 rounded-full bg-red-500 shadow border-2 border-white flex items-center justify-center font-semibold text-xs">
            <span>!</span>
          </div>
        ),
      });
    },
    [state, toast]
  );

  const success = useCallback(
    (props: ToastWithFixedIconProps) => {
      toast({ ...props, icon: <CheckCircleIcon className="h-5 w-5" /> });
    },
    [state, toast]
  );

  return (
    <>
      <ToastsContext.Provider value={{ toast, close, error, success }}>
        {children}
        {state.visibleToasts.length > 0 && <ToastRegion {...props} state={state} />}
      </ToastsContext.Provider>
    </>
  );
}
