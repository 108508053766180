import type { AriaToastProps } from "@react-aria/toast";
import { useToast } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import React from "react";
import { Button } from "react-aria-components";

interface ToastProps<T> extends AriaToastProps<T> {
  state: ToastState<T>;
}

export function Toast<T extends React.ReactNode>({ state, ...props }: ToastProps<T>) {
  let ref = React.useRef(null);
  let { toastProps, titleProps, closeButtonProps } = useToast(props, state, ref);
  return (
    <Button
      {...closeButtonProps}
      className="outline-none w-full md:w-auto focus-visible:ring-slate-900 focus-visible:ring-2 rounded-md ring-offset-2 focus:bg-zinc-100 hover:bg-zinc-100"
    >
      <div
        {...toastProps}
        ref={ref}
        className="relative w-full md:min-w-96 bg-white rounded-lg border flex p-4 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
      >
        <div {...titleProps} className="text-sm">
          {props.toast.content}
        </div>
      </div>
    </Button>
  );
}
